body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(59, 57, 219);
}

iframe {
    width: 80%;
    height: 400px; /* Set height as needed */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1 {
  text-align: center;
  color: white;
}

h2 {
  color: white;
}

h3 {
  color: white;
}

h4 {
  color: white;
}

h5 {
  text-align: center;
  color: white;
}

p {
  color: white;
}

img.apple {
  padding-left: 60px
}

.container {
  display: flex;
  justify-content: center;
  list-style-type: none;
}
.center {
  width: 30%;
}
.form-center {
  width: 50%;
  padding-left: 25%;
}

.ul {
  display: flex;
  justify-content: center;
  
}

li {
  color: white;
}



a.link {
  color: white;
}

.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}



li.navButton {
  padding-right: 60px;
  color: white;
}